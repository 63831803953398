import { formatTime, parseTime, cdn, desens } from '@/utils/index.js';
import config from '@/utils/config';
export default {
  components: {},
  filters: {
    formatTime,
    parseTime,
    cdn,
    desens
  },
  data() {
    return {
      disabledModules: process.env.VUE_APP_DISABLED_ROUTES ? process.env.VUE_APP_DISABLED_ROUTES.split(',') : []
    };
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    cdn,
    // 跳转到浏览器地址
    toBrowser(action, hash) {
      if (!config.BROWSER) {
        return this.$message.error('暂不支持浏览');
      }
      let url = config.BROWSER + action + '/' + hash;
      window.open(url);
    },
    desens,
    copy(text) {
      console.log('text', text);
      var copyInput = document.createElement('input');
      copyInput.setAttribute('value', text);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        var copyed = document.execCommand('copy');
        if (copyed) {
          document.body.removeChild(copyInput);
          this.$message.success('复制成功');
        }
      } catch {
        this.$message.error('复制失败，请手动复制');
      }
    }
  }
};