import config from './config';

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (!time || +time === 0) {
    return '-';
  }
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option = '{y}-{m}-{d}') {
  // 字符串格式的日期需要转为时间戳(不是纯数字)
  if (!/^\d+$/.test(time)) {
    time = new Date(time.replace(/\-/g, '/')).getTime();
  }

  // 10位的时间戳需要转为 毫秒时间戳
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();
  const diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {number} size
 * @returns {string}
 */
export function formatSize(size) {
  if (typeof size !== 'number') {
    size = parseInt(size);
  }
  const arrUnit = ['B', 'K', 'M', 'G', 'T', 'P'];
  const baseStep = 1024;
  const unitCount = arrUnit.length;
  let unitIndex = 0;
  while (size >= baseStep && unitIndex < unitCount - 1) {
    unitIndex++;
    size /= baseStep;
  }
  size = size.toFixed(2);
  return size + '' + arrUnit[unitIndex];
}

// 补全静态文件地址
export function cdn(url, size = null) {
  if (!url) return '';
  if (url.substr(0, 4) === 'http') return url;
  if (url.substr(0, 1) === '.') url = url.substr(1);
  if (size) {
    if (/^material\/[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/.test(url)) {
      url = url.replace(/^(material\/[a-zA-Z0-9]+)(\.)([a-zA-Z0-9]+)$/, "$1@" + size + ".$3");
    } else if (/^storage\/[a-zA-Z0-9\/]+\.[a-zA-Z0-9]+$/.test(url)) {
      url = url.replace(/^storage\/([a-zA-Z0-9\/]+)(\.)([a-zA-Z0-9]+)$/, "thumb/$1@" + size + ".$3");
      /* let matchs = /storage([\/a-zA-Z0-9]+)\.(jpg|png|jpeg)/.exec(url)
      if(matchs && matchs.length == 3){
        url = 'thumb' + matchs[1] + '@' + size + '.' + matchs[2]
      } */
    }
  }
  return config.CDN_DOMAIN + '/' + url;
}
export function desens(value) {
  if (!value || !value.length) return '-';
  if (value.length <= 6) {
    return value;
  }
  return value.substr(0, 6) + '...' + value.substr(-4);
}