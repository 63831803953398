var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "app-wrapper" },
    [
      _c("el-header", { staticClass: "header" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("../assets/logo-white.png") },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "menu",
          attrs: { src: require("../assets/menu.png") },
          on: { click: _vm.toggleMenu },
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "right" },
          [
            _c("el-avatar", {
              staticClass: "head",
              attrs: { size: 30, src: _vm._f("cdn")(_vm.avatar) },
            }),
            _vm._v(" "),
            _c(
              "el-dropdown",
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(
                    "\n\t\t\t    " + _vm._s(_vm.$store.state.user.name) + " "
                  ),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/config/setpass" } },
                          [_vm._v("修改密码")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      {
                        attrs: { divided: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout($event)
                          },
                        },
                      },
                      [_vm._v("退出系统")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            {
              staticClass: "menus",
              class: { active: _vm.showMenuOnMobile },
              attrs: { width: "160px" },
            },
            [
              _c(
                "el-menu",
                {
                  attrs: {
                    "default-active": _vm.rootPath,
                    "text-color": "#8a8a8a",
                  },
                  on: { select: _vm.menuSelect },
                },
                [
                  _vm._l(_vm.routes, function (item) {
                    return [
                      !item.hidden
                        ? _c(
                            "el-menu-item",
                            { key: item.path, attrs: { index: item.path } },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  width: "1.2em",
                                  height: "1.2em",
                                  "vertical-align": "middle",
                                  "margin-right": "7px",
                                },
                                attrs: { "icon-class": item.meta.icon },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.meta.title))]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-container",
            [
              _c(
                "el-header",
                { staticClass: "breadcrumb" },
                [
                  _c(
                    "el-breadcrumb",
                    { attrs: { "separator-class": "el-icon-arrow-right" } },
                    [
                      _c(
                        "el-breadcrumb-item",
                        { attrs: { to: { path: "/" } } },
                        [_vm._v("管理中心")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.$route.matched, function (item) {
                        return _c("el-breadcrumb-item", { key: item.path }, [
                          _vm._v(_vm._s(item.meta.title)),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-container",
                [
                  _vm.subMenus && _vm.subMenus.length > 1
                    ? _c(
                        "el-aside",
                        {
                          staticClass: "sub-munus",
                          class: { active: _vm.showSubMenuOnMobile },
                          attrs: { width: "160px" },
                        },
                        [
                          _c(
                            "el-menu",
                            {
                              attrs: {
                                "default-active": _vm.subPath,
                                "text-color": "#8a8a8a",
                              },
                              on: { select: _vm.subSelect },
                            },
                            [
                              _vm._l(_vm.subMenus, function (item, index) {
                                return !item.hidden
                                  ? [
                                      item.children &&
                                      _vm.filterHidden(item.children).length > 1
                                        ? _c(
                                            "el-submenu",
                                            {
                                              key: item.path,
                                              attrs: {
                                                index: _vm.resolvePath(
                                                  item.path
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-location",
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.name) + "1"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                item.children,
                                                function (child, childIndex) {
                                                  return [
                                                    !child.hidden
                                                      ? _c(
                                                          "el-menu-item",
                                                          {
                                                            key: childIndex,
                                                            attrs: {
                                                              index:
                                                                _vm.resolvePath(
                                                                  item.path,
                                                                  child.path
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                child.meta.title
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : item.children &&
                                          _vm.filterHidden(item.children)
                                            .length == 1
                                        ? _c(
                                            "el-menu-item",
                                            {
                                              key: item.path,
                                              attrs: {
                                                index: _vm.resolvePath(
                                                  item.path,
                                                  item.children[0].path
                                                ),
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-menu",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.children[0].name
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "el-menu-item",
                                            {
                                              key: item.path,
                                              attrs: {
                                                index: _vm.resolvePath(
                                                  item.path
                                                ),
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  width: "0.5em",
                                                  height: "0.5em",
                                                },
                                                attrs: {
                                                  "icon-class": "submenu",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(_vm._s(item.meta.title)),
                                              ]),
                                            ],
                                            1
                                          ),
                                    ]
                                  : _vm._e()
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-container",
                    [
                      _c(
                        "el-scrollbar",
                        {
                          staticClass: "app-main",
                          attrs: { "wrap-style": "overflow-x:hidden;" },
                        },
                        [_c("router-view", { ref: "routerView" })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }