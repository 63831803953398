/* import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router' */
import Cookies from 'js-cookie';
window.cookieses = Cookies;
const KEY = 'adminUserInfo';
const getDefaultState = () => {
  let cookieData = Cookies.getJSON(KEY) || {};
  return {
    token: cookieData.token || null,
    user: cookieData.user || null,
    name: cookieData.name || '',
    avatar: cookieData.avatar || '',
    privileges: cookieData.privileges || [],
    is_super: cookieData.is_super || false
  };
};
const state = getDefaultState();
const mutations = {
  reset: state => {
    Object.assign(state, getDefaultState());
  },
  login(state, data) {
    for (let key in state) {
      if (data[key] !== undefined) {
        state[key] = data[key];
      }
    }
    Cookies.set(KEY, data);
  },
  /* // 批量更新信息
  set_info: (state, json) => {
    for (const x in json) {
      state[x] = json[x]
      Cookies.set('user_' + x, json[x])
    }
  },
  // 更新token
  set_token: (state, token) => {
    state.token = token
    Cookies.set('user_token', token)
  },
  // 更新名称
  set_name: (state, name) => {
    state.name = name
    Cookies.set('user_name', name)
  },
  // 更新头像
  set_avatar: (state, avatar) => {
    state.avatar = avatar
    Cookies.set('user_avatar', avatar)
  }, */
  // 退出登录
  logout: () => {
    Cookies.remove(KEY);
    location.href = location.href;
    /* 
    state.token = null
    state.name = null
    state.avatar = null
    Cookies.remove('user_token')
    Cookies.remove('user_name')
    Cookies.remove('user_avatar')
    Cookies.remove('user_avatar') */
  }
};
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};