import Vue from 'vue';
import 'normalize.css/normalize.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss';
import icons from './icons';
import App from './App';
import store from './store';
import router from './router';
import mixin from '@/mixins/default';
import '@/icons';
import '@/permission';
import '@/utils/request';
Vue.use(ElementUI);

/* Vue.prototype.$store = store */
/* Vue.prototype.$get = request.get
Vue.prototype.$post = request.post */

Vue.config.productionTip = false;
Vue.config.errorHandler = function (err, vm, info) {
  console.log(err, vm, info);
};
Vue.mixin(mixin);
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});