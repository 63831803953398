import Cookies from 'js-cookie';
import request from '@/utils/request';
let localStates = {};
if (localStorage.appStates) {
  try {
    localStates = JSON.parse(localStorage.appStates);
  } catch (e) {
    alert('请重新登录');
    localStorage.removeItem('appStates');
  }
}
const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  config: {},
  // 服务器返回的动态配置
  states: localStates
};
const mutations = {
  CONFIG: (state, provider) => {
    state.config = provider;
  },
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
  SET_STATES(state, states) {
    state.states = states;
    localStorage.appStates = JSON.stringify(states);
  }
};
const actions = {
  toggleSideBar({
    commit
  }) {
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar({
    commit
  }, {
    withoutAnimation
  }) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice({
    commit
  }, device) {
    commit('TOGGLE_DEVICE', device);
  },
  getStates({
    commit
  }) {
    let data = {
      "status": [{
        "model": "status",
        "type": "",
        "value": 1,
        "label": "正常",
        "effect": "plain"
      }, {
        "model": "status",
        "type": "danger",
        "value": 0,
        "label": "禁用",
        "effect": "light"
      }, {
        "model": "status",
        "value": -1,
        "label": "已使用",
        "type": "default",
        "effect": "dark"
      }],
      "order": [{
        "model": "order",
        "value": -1,
        "label": "取消",
        "type": "danger",
        "effect": "dark"
      }, {
        "model": "order",
        "value": 0,
        "label": "待支付",
        "type": "default",
        "effect": "plain"
      }, {
        "model": "order",
        "value": 1,
        "label": "已支付",
        "type": "primary",
        "effect": "dark"
      }, {
        "model": "order",
        "value": 2,
        "label": "已完成",
        "type": "success",
        "effect": "dark"
      }]
    };
    commit('SET_STATES', data);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};