import axios from 'axios';
import { Notification, Message } from 'element-ui';
import store from '@/store';
import qs from 'querystring';
import config from './config';
import Vue from 'vue';
import router from '@/router';
// import { getToken } from '@/utils/auth'

// 创建一个http请求实例
const service = axios.create({
  baseURL: config.API,
  // process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // 超时时间
});

// 请求拦截器，加工http请求
service.interceptors.request.use(config => {
  // 在发起请求之前的操作
  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  if (store.state.user && store.state.user.token) {
    config.headers['Authorization'] = store.state.user.token;
  }

  /* if (typeof config.data !== 'string') {
      config.data = qs.stringify(config.data)
  } */
  return config;
}, error => {
  // do something with request error
  console.log('HTTP错误', error);
  return Promise.reject(error);
});

// 相应拦截器，处理返回的数据
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
response => {
  let data = response.data;
  if (data.errcode) {
    if (data.errors) {
      let errArr = [];
      for (let key in data.errors) {
        let item = data.errors[key];
        errArr.push(item instanceof Array ? item.join("\n") : item);
      }
      Notification.error({
        title: data.message || '请求失败',
        dangerouslyUseHTMLString: true,
        message: errArr.join("<br/>")
      });
    } else {
      Message({
        message: data.message || '请求失败',
        type: 'error',
        duration: 3 * 1000
      });
    }
    if (data.errcode == 401) {
      router.replace("/login");
    }
    return Promise.reject(data);
  }
  return data;
}, error => {
  let message = '网络请求失败';
  if (error.response && error.response.data && error.response.data.message) {
    message = error.response.data.message;
  }
  Message({
    message: message,
    type: 'error',
    duration: 3 * 1000
  });

  // 未授权跳转到登录
  if (error.response && error.response.status == 401) {
    router.replace("/login");
  }
  return Promise.reject(error);
});
Vue.prototype.$post = service.post;
Vue.prototype.$put = service.put;
Vue.prototype.$get = service.get;
Vue.prototype.$delete = service.delete;

/* // 挂在便捷方法 在组件内this.$get这样调用
Vue.prototype.$get = (...args) =>{
    return new Promise((resolve) => {
        service.get(...args).then((data) => {
        // 其他错误
        if(data.errcode){
            Message.error({
                message: data.message || '请求失败',
                type: 'error',
                duration: 3 * 1000
            })
          // return Promise.reject(data)
        }else {
            resolve(data)
        }
        
      }, () => {})
    })
}
  
Vue.prototype.$post = (...args) =>{
    return new Promise((resolve) => {
        service.post(...args).then((data) => {
        // 其他错误
        if(data.errcode){
            Message.error({
                message: data.message || '请求失败',
                type: 'error',
                duration: 3 * 1000
            })
          // return Promise.reject(data)
        }else {
            resolve(data)
        }
      }, () => {})
    })
}

Vue.prototype.$delete = (...args) =>{
    return new Promise((resolve) => {
        service.delete(...args).then((data) => {
        // 其他错误
        if(data.errcode){
            Message.error({
                message: data.message || '请求失败',
                type: 'error',
                duration: 30 * 1000
            })
          // return Promise.reject(data)
        }else {
            resolve(data)
        }
      }, () => {})
    })
} */

export default service;