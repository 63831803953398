import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

/* Layout */
import Layout from "@/layout";

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
let constantRoutes = [{
  path: "/",
  redirect: "/login",
  hidden: true
}, {
  path: "/login",
  component: () => import("@/views/index/login"),
  hidden: true
}, {
  path: "/404",
  component: () => import("@/views/404"),
  hidden: true
}, {
  path: "/users",
  component: Layout,
  redirect: "/users/index",
  name: "User",
  meta: {
    title: "用户管理",
    icon: "user"
  },
  children: [{
    path: "index",
    name: "userList",
    component: () => import("@/views/users/index"),
    meta: {
      title: "用户管理",
      icon: "user"
    }
  }]
}, {
  path: "/orders",
  component: Layout,
  redirect: "/orders/index",
  name: "Order",
  meta: {
    title: "订单管理",
    icon: "orders"
  },
  children: [{
    path: "index",
    name: "vipOrderList",
    component: () => import("@/views/orders/index"),
    meta: {
      title: "VIP订单",
      icon: "form"
    }
  }, {
    path: "package",
    name: "packageOrderList",
    component: () => import("@/views/orders/package"),
    meta: {
      title: "套餐包订单",
      icon: "form"
    }
  }]
}, {
  path: "/goods",
  component: Layout,
  redirect: "/goods/index",
  name: "Goods",
  meta: {
    title: "商品管理",
    icon: "mbox"
  },
  children: [{
    path: "index",
    name: "vipList",
    component: () => import("@/views/goods/index"),
    meta: {
      title: "VIP管理",
      icon: "form"
    }
  }, {
    path: "vip/edit",
    name: "vipEdit",
    hidden: true,
    component: () => import("@/views/goods/vip/edit"),
    meta: {
      title: "VIP管理",
      icon: "form"
    }
  }, {
    path: "package",
    name: "packageList",
    component: () => import("@/views/goods/package"),
    meta: {
      title: "套餐包管理",
      icon: "form"
    }
  }, {
    path: "package/edit",
    name: "packageEdit",
    hidden: true,
    component: () => import("@/views/goods/package/edit"),
    meta: {
      title: "套餐包管理",
      icon: "form"
    }
  }, {
    path: "cates",
    name: "cates",
    component: () => import("@/views/goods/cates"),
    meta: {
      title: "分类管理",
      icon: "form"
    }
  }, {
    path: "bots",
    name: "bots",
    component: () => import("@/views/goods/bots"),
    meta: {
      title: "BOT管理",
      icon: "form"
    }
  }]
}, {
  path: "/activity",
  component: Layout,
  redirect: "/activity/index",
  name: "Activity",
  meta: {
    title: "活动管理",
    icon: "airdrop"
  },
  children: [{
    path: "index",
    name: "activity",
    component: () => import("@/views/activity/index"),
    meta: {
      title: "兑换活动",
      icon: "table"
    }
  }, {
    path: "edit",
    name: "activityEdit",
    hidden: true,
    component: () => import("@/views/activity/edit"),
    meta: {
      title: "兑换活动",
      icon: "form"
    }
  }]
}, {
  path: "/apps",
  component: Layout,
  redirect: "/apps/tests",
  name: "Apps",
  meta: {
    title: "应用管理",
    icon: "category"
  },
  children: [{
    path: "tests",
    name: "apps",
    component: () => import("@/views/apps/tests/index"),
    meta: {
      title: "趣味测试",
      icon: "table"
    }
  }, {
    path: "/apps/tests/edit",
    name: "testsEdit",
    hidden: true,
    component: () => import("@/views/apps/tests/edit"),
    meta: {
      title: "趣味测试",
      icon: "form"
    }
  }, {
    path: "/tests",
    name: "huitu",
    component: () => import("@/views/apps/tests/huitu"),
    meta: {
      title: "绘图广场",
      icon: "form"
    }
  }, {
    path: "/apps/tests/hui",
    name: "hui",
    hidden: true,
    component: () => import("@/views/apps/tests/hui"),
    meta: {
      title: "绘图广场",
      icon: "form"
    }
  }, {
    path: "/apps/tests/category",
    name: "category",
    component: () => import("@/views/apps/tests/category"),
    meta: {
      title: "趣味测试分类管理",
      icon: "form"
    }
  }, {
    path: "/apps/tests/testsCategory",
    name: "testsCategory",
    component: () => import("@/views/apps/tests/testsCategory"),
    meta: {
      title: "达人分类管理",
      icon: "form"
    }
  }]
}, {
  path: "/admins",
  component: Layout,
  redirect: "/admins/index",
  name: "Admins",
  meta: {
    title: "管理员管理",
    icon: "manager"
  },
  children: [{
    path: "index",
    name: "AdminList",
    component: () => import("@/views/admins/index"),
    meta: {
      title: "管理员列表",
      icon: "table"
    }
    // beforeEnter:(to,from,next)=>{
    // 	if( this.$store.state.user.name=='admin'){
    // 		next()
    // 	}else{
    // 		alert('您没有权限访问')
    // 	}

    // }
  }]
}, {
  path: "/config",
  component: Layout,
  redirect: "/config/site",
  name: "系统设置",
  meta: {
    title: "系统设置",
    icon: "setting"
  },
  children: [{
    path: "setpass",
    name: "修改密码",
    hidden: true,
    component: () => import("@/views/config/setpass"),
    meta: {
      title: "修改密码",
      icon: "table"
    }
  }, {
    path: "register",
    name: "注册配置",
    component: () => import("@/views/config/register"),
    meta: {
      title: "注册配置"
    }
  }, {
    path: "invite",
    name: "推荐配置",
    component: () => import("@/views/config/invite"),
    meta: {
      title: "推荐配置"
    }
  }, {
    path: "adv",
    name: "广告配置",
    component: () => import("@/views/config/adv"),
    meta: {
      title: "广告配置"
    }
  }]
}, {
  path: "/log",
  component: Layout,
  redirect: "/log/paylog",
  name: "日志",
  meta: {
    title: "日志",
    icon: "category"
  },
  children: [{
    path: "paylog",
    name: "支付记录",
    component: () => import("@/views/log/paylog"),
    meta: {
      title: "支付记录",
      icon: "table"
    }
  }, {
    path: "codelog",
    name: "礼包码记录",
    component: () => import("@/views/log/codelog"),
    meta: {
      title: "礼包码记录",
      icon: "table"
    }
  }, {
    path: "chatlog",
    name: "对话次数记录",
    component: () => import("@/views/log/chatlog"),
    meta: {
      title: "对话次数记录",
      icon: "table"
    }
  }, {
    path: "drawlog",
    name: "绘画次数记录",
    component: () => import("@/views/log/drawlog"),
    meta: {
      title: "绘画次数记录",
      icon: "table"
    }
  }, {
    path: "adminlog",
    name: "操作记录",
    component: () => import("@/views/log/adminlog"),
    meta: {
      title: "操作记录",
      icon: "table"
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: "*",
  redirect: "/404",
  hidden: true
}];
const disabled = process.env.VUE_APP_DISABLED_ROUTES ? process.env.VUE_APP_DISABLED_ROUTES.split(",") : [];
function filterRoutes(routes) {
  let filtedRoutes = [];
  routes.forEach(route => {
    if (disabled.indexOf(route.name) < 0) {
      if (route.children) {
        route.children = filterRoutes(route.children);
      }
      filtedRoutes.push(route);
    }
  });
  return filtedRoutes;
}
let enabledRoutes = filterRoutes(constantRoutes);
const createRouter = () => new Router({
  mode: "history",
  // require service support
  base: "/",
  scrollBehavior: () => ({
    y: 0
  }),
  routes: enabledRoutes
});
const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;