//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import path from 'path';
import store from '@/store';
// window.path = path

const hasPermission = route => {
  // 超管显示全部
  if (store.state.user.is_super) {
    return true;
  }
  // 有权限要求的则判断权限
  if (route.meta && route.meta.permission) {
    let userPrivilages = store.state.user.privileges;
    // 用户未设置权限的则直接返回false
    if (!userPrivilages || !userPrivilages.length) return false;
    // 有准确的权限设置
    if (userPrivilages.includes(route.meta.permission)) {
      return true;
    }

    // 如果是一级菜单设置了一级权限，则有任意二级权限也需要显示菜单
    if (route.meta.permission.indexOf('.') < 0) {
      // console.log('检查权限3' , route.meta.permission, userPrivilages.join("|").indexOf(route.meta.permission + '.') > -1)
      return userPrivilages.join("|").indexOf(route.meta.permission + '.') > -1;
    }
  }
  return true;
};
const fileterRoutes = routes => {
  let arr = [];
  routes.forEach(route => {
    const tmp = {
      ...route
    };
    if (hasPermission(tmp)) {
      if (tmp.children) {
        tmp.children = fileterRoutes(tmp.children);
      }
      arr.push(tmp);
    }
  });
  return arr;
};
export default {
  name: 'Layout',
  computed: {
    routes() {
      return fileterRoutes(this.$router.options.routes);
    }
  },
  data() {
    return {
      pageLock: false,
      // 是否已初始化
      rootPath: '/',
      subPath: '',
      subMenus: [],
      avatar: this.$store.state.user.avatar != '' ? this.$store.state.user.avatar : '/static/logo.png',
      showMenuOnMobile: false,
      // 在手机上显示菜单
      showSubMenuOnMobile: false // 是否在手机上显示子菜单
    };
  },
  watch: {
    subMenus(val) {
      // 锁定页面的时候，不自动跳转到子菜单的第一个页面
      if (this.pageLock) return false;
      val.every(item => {
        if (!item.hidden) {
          // 有子菜单（三级菜单）则跳转到第一个三级菜单
          if (item.children) {
            let children = this.filterHidden(item.children);
            if (children.length) {
              this.$router.push(this.resolvePath(item.path, children[0].path));
              return false;
            }
          }
          this.subSelect(this.resolvePath(item.path));
          return false;
        }
        return true;
      });
    },
    '$route.path'(val) {
      this.updateMenu();
    }
  },
  created() {
    this.updateMenu();
  },
  methods: {
    // 调整菜单显示
    updateMenu() {
      this.rootPath = this.$route.matched[0].path || '/';
      this.pageLock = true;
      this.menuSelect(this.rootPath);
      if (this.$route.matched.length > 1) {
        this.subSelect(this.$route.matched.slice(-1)[0].path);
      }
      this.$nextTick(() => {
        this.pageLock = false;
      });
    },
    // 补全路径
    resolvePath(...paths) {
      return path.resolve(this.rootPath, ...paths);
    },
    // 过滤隐藏的菜单
    filterHidden(menus) {
      return menus.filter(item => {
        return !item.hidden;
      });
    },
    menuSelect(path) {
      this.rootPath = path;

      // 匹配子菜单
      this.routes.every(item => {
        if (item.path == path) {
          this.subMenus = item.children.filter(item => {
            return !item.hidden;
          });
          this.showSubMenuOnMobile = true;
          return false;
        }
        return true;
      });
    },
    subSelect(path) {
      console.log(path);
      this.subPath = path;
      if (!this.pageLock) {
        this.$router.push(path);
      }
      this.showMenuOnMobile = false;
      this.showSubMenuOnMobile = false;
    },
    logout() {
      this.$store.commit('user/logout');
      this.$router.replace(`/?redirect=${this.$route.fullPath}`);
    },
    toggleMenu() {
      this.showMenuOnMobile = !this.showMenuOnMobile;
      this.showSubMenuOnMobile = this.showMenuOnMobile;
    }
  }
};