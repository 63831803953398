import router from './router';
import store from './store';
// import { Message } from 'element-ui'
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
// import {  getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title';
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

const whiteList = ['/login']; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // 进度条
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);
  // 在白名单中或者是已登录
  if (whiteList.indexOf(to.path) !== -1 || store.state.user.token) {
    // in the free login whitelist, go directly
    next();
  } else {
    // 否则跳转到登录页面
    next(`/login?redirect=${to.path}`);
  }
});
router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});